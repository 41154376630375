<template>
  <b-container class="mt-4">
    <b-card header-class="header">
      <template #header>
        <label v-text="singleCompetition.name" class="mr-2 text-white"></label>
        <b-button
          variant="primary"
          @click="getCompetitionDetailPage"
          class="ml-4"
          >Yarışma Bilgileri</b-button
        >
        <b-button variant="primary" to="/competitions" class="ml-4"
          >Yarışmalar</b-button
        >

        <b-button
          variant="danger"
          class="float-right ml-4"
          @click="printParticipantList"
        >
          PDF Al
        </b-button>


        <download-excel
          class="btn btn-success float-right ml-4"
          :fetch="getData"
          :fields="
            reports.competitionParticipantClub.fields.reduce((obj, e) => {
              obj[e] = e;
              return obj;
            }, {})
          "
          :worksheet="reports.competitionParticipantClub.title"
          name="filename.xls"
        >
          Excel al
        </download-excel>
      </template>
      <PMSTable
        :tableFields=getFields()
        :tableItems="getCompetitionGroups"
        :erasable="true"
        :selectable="false"
        fromClub
        @multipleDeleteAction="deleteItem"
        :limit="10000"
        deleteMessage="Yarışma grubunu silmek istediğinizden emin misiniz?"
      ></PMSTable>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import jsPDF from "jspdf";
import PMSTable from "../../../components/tables/GeneralTable.vue";
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "../../../store/constants/reports";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    PMSTable,
    "download-excel": JsonExcel,
  },
  computed: {
    ...mapGetters({
      competitionGroups: "getCompetitionGroups",
      getSelections: "getManagementItems",
      singleCompetition: "getSingleCompetition",
    }),

    getCompetitionGroups: {
      get() {
        return this.competitionGroups.map((e) => {
          e.personels = [];
          e.personels.push(e.personel1);
          if (e.personelId2) e.personels.push(e.personel2);
          if (e.personelId3) e.personels.push(e.personel3);
          if (e.personelId4) e.personels.push(e.personel4);
          return e;
        });
      },
    },
  },
  data() {
    return {
      reports,
      fields: [
        { key: "id", label: "Sıra No" },
        { key: "formOfParticipation", label: "Katılım Şekli" },
        { key: "categoryRel.title", label: "Kategori" },
        { key: "boatTypeRel.title", label: "Tekne Sınıfı" },
        { key: "distanceRel.title", label: "Mesafe" },
        { key: "names", label: "Personeller" },
        { key: "events", label: "İşlemler" },
      ],
    };
  },
  methods: {
    parseForm(form) {
      let newForm = form;

      return newForm;
    },
    deleteItem(index) {
      this.$store.dispatch("deleteCompetitionGroup", {
        params: {
          clubId: this.$store.getters.getUser.id,
          competitionId: this.$route.params.id,
        },
        items: [index],
      });
    },
    async getData(){
      const data =  await reportHelper.getPdfItemsFromServer(
          reports.competitionParticipantClub.endpoint,
          { id: this.$route.params.id, clubId: this.$store.getters.getUser.id }
        );
      return data.map((e) => {
        const row = {};
        reports.competitionParticipantClub.fields.forEach((element, index) => {
          row[element] = e[index];
        });

        return row;
      });
    },

    getCompetitionDetailPage() {
      this.$router.push({
        name: "CompetitionDetail",
        params: { id: this.$route.params.id },
      });
    },
    getFields(){
      const fields = this.fields;
      if (this.singleCompetition.endingDate != "") {
        return fields.filter((e) => e.key != "events");
      }
      return fields;
    },
    printClubCompetitionList() {
      const doc = new jsPDF();
      const contentHtml = this.$refs.hello.innerHTML;
      doc.html(contentHtml, {
        callback: function (doc) {
          doc.save("output.pdf");
        },
        x: 10,
        y: 10,
      });
    },
    async printParticipantList() {
      reportHelper.generateHTML(
        reports.competitionParticipantClub.title +
          " \n" +
          this.$store.getters.getUser.userName +
          " \n" +
          this.singleCompetition.name +
          " Katılımcı Listesi",
        reports.competitionParticipantClub.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.competitionParticipantClub.endpoint,
          { id: this.$route.params.id, clubId: this.$store.getters.getUser.id }
        )
      );
    },
  },

  created() {
    this.$store.dispatch("initClubCompetitionGroups", {
      clubId: this.$store.getters.getUser.id,
      competitionId: this.$route.params.id,
    });
    this.$store.dispatch("initSingleCompetition", this.$route.params.id);
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>